$leftbar: 14vw;
$placeholder: #ffffff33;
$accent: #f3bd32;

body {
  background: #181826;
  color: white;
  font-family: karla;
  div {
    // transition: 0.5s ease-out;
  }
}
.modalView > div {
  margin: 0 !important;
}

.mainblock {
  color: white;
  width: calc(94vw - #{$leftbar});
  margin-left: $leftbar;
  height: 100vh;
  padding: 3vh 3vw;
  position: fixed;
  overflow: auto;

  > div {
    margin: 0 7vw 7vw 7vw;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .top-header {
    display: flex;
    margin: 0 7vw;
    overflow: hidden;
    min-height: 26%;
    align-items: flex-start;
    justify-content: space-between;
  }

  .user-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    > div:first-child {
      position: relative;
      cursor: pointer;
    }
    .connect-button {
      margin-left: 30px !important;
      font-size: 0.85rem;
      min-width: 120px;
      padding: 5px 10px !important;
    }
    img {
      width: 16px;
    }
    span {
      width: 13px;
      height: 13px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: chocolate;
      color: white;
      font-size: 0.7rem;
      border-radius: 20px;
      position: absolute;
      top: -3px;
      left: 8px;
    }
  }
}

.page-heading {
  color: $accent;
  font-size: 1.3em;
  font-weight: 600;
  text-align: left;
  // margin: 50px 0;
  padding: 10px;
}
.connect-button {
  background: #00b7c1bb;
  border-radius: 10px;
  padding: 8px 20px !important;
  display: inline-block;
  text-transform: uppercase;
  width: initial !important;
  color: white;
  cursor: pointer;
}

.connect-button:hover {
  background: #00b7c17a;
}

.leftbar {
  background: #6e70810d;
  position: fixed;
  top: 0;
  width: $leftbar;
  height: 100vh;

  .app-icon {
    width: 100%;
    img {
      width: 52%;
      padding: 30px 0;
    }
    height: 28%;
  }
  .leftbar-item {
    display: flex;
    cursor: pointer;
    padding: 13px;
    height: 12px;
    font-family: karla;
    font-weight: 600;
    color: #9a999f;
    font-size: 0.9em;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    transition: 0.5s ease-out;
    padding-left: 3vw;
    > div:first-child {
      margin-right: 15px;
      width: 15%;
    }
    img {
      width: 15px;
    }
  }

  .leftbar-item:hover {
    color: #ffc33090;
  }
}

.back-button {
  width: 100%;
  float: left;
  margin: 10px 0;
  > img {
    cursor: pointer;
    width: 30px;
    float: left;
  }
}
.topbar {
  display: none;
}
.table-class {
  overflow: auto;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .table-class {
    ::-webkit-scrollbar {
      width: 0.2vw;
    }
  }

  .leftbar {
    display: none;
  }

  .mainblock {
    margin-left: 0;
    width: 100%;
    padding: 0;
    margin-top: 50px;
    height: calc(100vh - 50px);
    .top-header {
      min-height: 80px;
      margin: 30px 2vw 30px 2vw;

      // flex-direction: column-reverse;
    }
    .page-heading {
      font-size: 1.15em;
      padding-right: 0px;
      white-space: nowrap;
      margin: 10px 5px !important;
    }
    .user-panel {
      // margin-left: 40%;
      .connect-button {
        margin-left: 0 !important;
      }
    }
    > div {
      margin: 2vw 30px;
    }
  }
  .topbar {
    display: block;
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    .navbar-light .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.7)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    .leftbar-item {
      display: flex;
      justify-content: flex-start;
      padding: 5px;
      cursor: pointer;

      > div {
        padding: 4px;
        text-align: left;
        text-transform: uppercase;
      }
      > div:first-child {
        width: 10%;
      }
    }
    > nav {
      padding: 4px;
      background: #152b39;
    }
    .app-icon {
      img {
        height: 35px;
      }
    }
  }
}

//checkboxes

.checkbox {
  --background: rgba(255, 255, 255, 0.432);
  --border: #1146531c;
  --border-hover: #157968a2;
  --border-active: #00b7c1bb;
  --tick: #15172d;
  position: relative;
  input,
  svg {
    width: 18px;
    height: 18px;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
    &:hover {
      --s: 2px;
      --b: var(--border-hover);
    }
    &:checked {
      --b: var(--border-active);
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }
  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;
        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }
    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }
  &.bounce {
    --stroke: var(--tick);
    input {
      &:checked {
        --s: 11px;
        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }
    svg {
      --scale: 0;
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.notification-modal {
}

table {
  td,
  th {
    text-align: left;
  }

  td:first-child,
  th:first-child {
    padding-left: 40px;
  }

  td:last-child,
  th:last-child {
    text-align: right;
    padding-right: 40px;
  }
}
