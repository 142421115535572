$primary: #15172d;

.create {
  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .create-table {
    td {
      width: 25%;
    }
  }

  .mint {
    background: #00b7c11e;
    width: 200px;
    min-height: 150px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    padding: 0 40px 10px 40px;
    border-radius: 15px;
    > div {
      width: 100%;
      margin: 15px 0;
      padding: 10px;
    }
    .header {
      height: 30px;
      width: auto;
      position: relative;
      top: -15px;
      margin: 0;
      padding: 2px 30px;
      align-items: center;
      font-weight: 600;
      color: #cacbcd;
      background: $primary;
      border-radius: 10px;
      text-transform: uppercase;
      border: 1px solid #00b6c14d;
    }
    .details {
      color: #cacbcda8;
      font-size: 0.9rem;

      padding: 3px 0;
    }
  }
  .create-table {
    width: 60%;
  }
  .item {
    position: relative;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    color: #cacbcd;
    border-radius: 10px;

    > input {
      background: $primary;
      outline: none;
      border: none;
      text-align: center;
      width: 100%;
      font-family: karla;
      font-size: 1.1rem;
      color: #cacbcd;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cacbcda4;
      font-family: karla;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cacbcda4;
      font-family: karla;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #cacbcda4;
      font-family: karla;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #cacbcda4;
      font-family: karla;
    }

    *:focus {
      outline: 0;
    }
  }
}

.create-modal {
  // width: 100%;
  margin: 2vw;
  flex-direction: column;

  .button-class {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 5px;
    display: block;

  }
  .item4 {
    border: 2px solid #00b6c1;
    width: 40% !important;
    padding: 4px !important;
  }

  .input-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > div {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 10px;
        margin: 3px;
        min-height: 8px;
      }
    }
  }

  .assetType {
    margin-top: -10px;
    background: #152836;
    min-width: 75px;
  }
  .section-a {
    min-height: 150px;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .section-data {
    span {
      white-space: nowrap;
    }
    div > input {
      background: $primary;
      outline: none;
      border: none;
      text-align: right;
      border-radius: 5px;
      width: 30%;
      font-family: karla;
      font-size: 1.1rem;
      color: #cacbcd;
      width:100%;
      padding-right:20px;
    }
  }

  .item {
    width: 50%;
    margin: 15px 0;
    padding: 10px;
  }
  .section {
    margin: 30px 0px 0px 0px;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    // flex-direction: column;
    .section-data > div {
      display: flex;
      justify-content: space-between;
      margin: 30px 20px;
      > span:first-child {
        color: #cacbcd77;
      }
      > span:first-child {
        color: #cacbcdc5;
      }
    }
    .section-block {
      background: #00b7c125;
      border-radius: 18px;
      margin: 5px 0 1vw 1vw;
      display: flex;
      padding: 10px 20px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      // flex-direction: column;
      position: relative;
      > span {
        position: absolute;
        top: 7px;
        right: 7px;
        cursor: pointer;
        > img {
          width: 18px;
          height: 18px;
        }
      }
      .item2 {
        padding: 3px;
        width: 85%;
        margin: 8px;
        input {
          color: #cacbcd70;
          font-size: 0.9rem;
          width: 90%;
        }
      }
    }
    .heading {
      text-transform: uppercase;
      text-align: left;
      margin-left: 1vw;

      color: #cacbcd7c;
    }
    > div:first-child {
      width: 35%;
      border: 1px solid #00b6c180;
      border-radius: 10px;
      background: $primary;
    }
    > div:last-child {
      width: 65%;
    }
  }

  .bottom-div {
    margin: 1px 0px;
    > diV {
      margin: 20px;
    }
  }
  .small-button {
    border-radius: 20px;
    border: 2px solid #00b6c1;
    padding: 3px 5px;
    color: #cacbcd;
    display: inline-block;
    min-width: 80px;
    // margin: 0 10px;
    cursor: pointer;
    font-size: 0.9rem;
  }

  .small-button:hover {
    border-radius: 10px;
    border: 2px solid #cacbcd73;
    background: rgba(0, 0, 0, 0.075);
    padding: 3px 5px;
    color: #00b6c1;
  }

  .asset-button {
    min-width: 186px;
    margin-right: 6px !important;
  }
}

@media only screen and (max-width: 800px) {
  .create {
    > div > div {
      margin: 0px;
    }
    .item {
      width: 60% !important;
    }
    .mint {
      padding: 0 20px !important;
      width: 80% !important;
      margin-bottom: 20px;
    }
    margin: 20px !important;
    > div {
      flex-direction: column !important;
      margin-top: 30px;
      align-items: center;
      > div {
        width: 95% !important;
        padding: 10px;
      }
    }
    .modalView {
      > div > div {
        width: 86% !important;
        left: 7% !important;
      }
    }
  }
  .create-modal {
    width: 100%;
    .item {
      width: 75% !important;
      > input {
        font-size: 15px;
      }
    }
    .bottom-div {
      flex-direction: column;
    }

    .button-class {
      display: flex;
      justify-content: space-between;
      margin-left: 0px;
      flex-wrap: wrap;
      // margin-right: 5px;
      > span:nth-child(3) {
        // margin-left: 20px;
      }
    }
    .section {
      flex-direction: column;
      align-items: center;
      .item {
        width: 100% !important;
      }
      .small-button {
        margin: 10px !important;
      }
      > div {
        width: 90% !important;
      }
      .item4 {
        width: 60% !important;
      }
      .section-block {
        margin: 2px;

        > span {
          top: 2px;
          right: 2px;
        }
      }
    }
  }
}
