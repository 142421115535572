$primary: #15172d;
.loan {
  td {
    cursor: pointer;
  }
}

.loan-table {
  td {
    width: 25%;
  }
}

.loan-modal {
  width: 100%;
  margin: 2vw;
  flex-direction: column;

  .item {
    position: relative;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    color: #cacbcd;
    border-radius: 10px;

    > input {
      background: $primary;
      outline: none;
      border: none;
      text-align: center;
      width: 100%;
      font-family: karla;
      font-size: 1.1rem;
      color: #cacbcda4;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cacbcd5d;

      font-family: karla;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cacbcd5d;

      font-family: karla;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #cacbcd5d;

      font-family: karla;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #cacbcd5d;
      font-family: karla;
    }

    *:focus {
      outline: 0;
    }
  }

  .item {
    width: 50%;
    margin: 15px 0;
    padding: 10px;
  }

  .section {
    margin: 10px 0px 20px 0px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    // flex-direction: column;
    .section-data > div {
      display: flex;
      justify-content: space-between;
      margin: 30px 20px;
      > span:first-child {
        color: #cacbcd77;
      }
      > span:first-child {
        color: #cacbcdc5;
      }
    }
    .section-block {
      background: #00b7c125;
      border-radius: 10px;
      margin: 1vw;
      display: flex;
      padding: 10px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .item2 {
        padding: 7px;
        width: 85%;
        margin: 8px;
        input {
          //   color: #cacbcd70;
          font-size: 0.9rem;
          width: 90%;
        }
      }
      .item3 {
        text-align: center;
        display: inline;
        text-transform: uppercase;
      }
      .item3:hover {
        opacity: 0.9;
        color: white !important;
        // background: rgba(255, 166, 0, 0.212);
        cursor: pointer;
      }
    }
    .heading {
      text-transform: uppercase;
      text-align: left;
      // margin-bottom: 2vw;
      font-weight: 600;
      color: #cacbcdda;
    }

    .input-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 20px;
      > div {
        width: 45%;
        text-align: left;
        // margin: 1%;
      }
      .item2 {
        margin: 0;
        padding: 7px;
        margin: 4px 0;
        width: 97% !important;
        input {
          text-align: left;
        }
      }
      .input-block {
        padding: 5px;
        > div:first-child {
          color: #cacbcd62;
          // text-transform: ;
        }
      }
    }
  }

  .bottom-div {
    margin: 15px 0px;
    > diV {
      margin: 20px;
    }
  }
  .big-button {
    width: 40% !important;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 800px) {
  .loan-modal .section .section-block {
    margin: 2vw;
  }

  .modalView > div > div {
    width: 86% !important;
    left: 7% !important;
  }

  .section {
    font-size: 0.9rem;
  }

  .loan-modal {
    .section-a {
      margin: 10px;
      background: #15172d;
      flex-direction: column;
      border-radius: 15px;
      padding: 10px 20px;
    }
    .modalView {
      .item {
        width: 95% !important;
      }
      .input-details {
        justify-content: space-evenly !important;
        > div {
          width: 40% !important;
        }
      }
    }

    .loan-modal .section .input-details .item2 {
      margin: 0;
      padding: 7px;
      margin: 4px 0;
      width: 90% !important;
    }
  }
}
