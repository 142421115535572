$accent: #f3bd32;

.dropdown-modal {
  background-color: #15172d;
  position: absolute;
  max-width: 100%;
  // margin: 20px 0 0 -15px;
  z-index: 70;
  top: 50px;
  right: 0;
  cursor: pointer;
  color: rgb(223, 222, 222);
  // border: 1px solid grey;
  border-radius: 5px;
  .option {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 7px;
    font-size: 1rem;
    // width: 100% !important;
    img {
      float: left;
    }
  }
  .option:hover {
    background: #f3bc3225;
    // color: black;
  }
}
.notification-modal {
  // background-color: #15172d;
  position: absolute;
  width: 300px;
  max-height: 300px;
  // margin: 20px 0 0 -15px;
  z-index: 70;
  top: 50px;
  right: 330px;
  cursor: pointer;
  color: white;
  // border: 1px solid grey;
  border-radius: 5px;

  .option {
    background: #816318;
    display: flex;
    padding: 7px 15px;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin: 10px;
    justify-content: center;
    font-size: 0.85rem;
    // width: 100% !important;
    .a {
      fill: white;
    }
    svg {
      transform: rotate(-90deg);
    }
    img {
      float: left;
    }
  }

  .option:hover {
    background: #6d510a;
  }
}

@media only screen and (max-width: 800px) {
  .notification-modal {
    right: 20px;
    top: 45px;
  }
}
