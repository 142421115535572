$primary: #15172d;

.stake {
  background: #00b7c11e;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 60%;
  padding: 3%;

  .header {
    img {
      width: 32px;
    }
  }
  .stake-block {
    width: 42%;
    margin: 3%;
    text-align: left;
    color: #cacbcda4;

    .status {
      border: 2px solid #00b7c19d;
      border-radius: 10px;
      margin-left: 50px;
      padding: 2px 3px;
      text-transform: uppercase;
      font-size: 0.7rem;
      text-align: center;
    }
    .details {
      font-size: 0.86rem;
    }
  }
  .connect-button {
    padding: 5px 35px !important;
    border-radius: 20px;
    font-size: 0.9rem;
  }
}

.stake-details {
  padding: 0 13vw;
  font-size: 0.94rem;

  table td,
  table th {
    padding: 0px;
  }

  > div {
    background: #00b7c12c;
    border-radius: 10px;
    padding: 2vw 4vw;
    color: #cacbcdc4;
    margin: 15px 0;

    display: flex;
    justify-content: space-between;

    > div:first-child {
      text-align: left;
      width: 55%;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }
  }
  > div:last-child {
    flex-direction: column;
  }
  .heading {
    text-transform: uppercase;
    // text-align: left;
    color: #cacbcd;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 15px 0;
    width: 100% !important;
    flex-direction: initial;
  }
  .table-details {
    width: 100%;
    table {
      width: 100%;
    }
  }
  .connect-button {
    padding: 5px 0px !important;
    border-radius: 20px;
    font-size: 0.9rem;
    margin: 7px 7px 0px 7px;
    width: 9vw !important;
  }

  .item {
    position: relative;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    // font-size: 1.3rem;
    margin-top: 15px;
    color: #cacbcd;
    border-radius: 15px;
    border: 2px solid #00b6c1;
    border-radius: 20px;
    // margin: 15px 0;
    padding: 3px;
    > div {
      font-size: 0.7rem;
      color: #cacbcd88;
      margin-bottom: -3px;
    }

    > input {
      background: $primary;
      outline: none;
      border: none;
      font-weight: 600;

      text-align: center;
      width: 90px;
      font-family: karla;
      font-size: 1rem;
      color: #cacbcd;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cacbcda4;
      font-family: karla;
      font-weight: 600;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cacbcda4;
      font-family: karla;
      font-weight: 600;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #cacbcda4;
      font-family: karla;
      font-weight: 600;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #cacbcda4;
      font-family: karla;
      font-weight: 600;
    }

    *:focus {
      outline: 0;
    }
  }
}

@media only screen and (max-width: 800px) {
  .stake {
    // flex-direction: column;
    height: initial;
    .stake-block {
      width: 90%;
    }
  }
  .stake-details {
    padding: 0 !important;
    font-size: 0.85rem;

    .item {
      width: 80% !important;
    }
    .connect-button {
      width: 120px !important;
      padding: 5px 0 !important;
    }
  }
}
