.table-div {
  // margin: 20px 0;
  .table-heading {
    display: inline-block;
    float: left;
    color: rgba(255, 255, 255, 0.753);
    margin-bottom: 20px;
    > div:first-child {
      > img {
        width: 20px;
        margin-right: 10px;
      }
      > span {
        font-family: Montserrat;
        font-size: 1rem;
        font-weight: 600;
      }
    }
    > div:last-child {
      height: 3px;
      width: 60%;
      margin-top: 8px;
      background: #00b6c1;
    }
  }
  table {
    width: 100%;

    td,
    th {
      padding: 15px;
      text-transform: capitalize;
    }
    th {
      padding-bottom: 30px;
      color: #7f7f7f;
    }
  }

  table {
    // border: 1px solid #ccc;
    border-collapse: collapse;
  }

  td {
    border: none;
    color: #cacbcd;
    img {
      // width: 30%;
    }
  }
}
