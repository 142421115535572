.modalView {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
}
.modalView > div {
  position: fixed;
  background: rgba(0, 0, 0, 0.377);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}

.modalView > div > div {
  padding-bottom: 5%;
  position: absolute;
}

.modalView > div > div > div {
  /* margin-bottom: 20%; */
  color: white;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #152836;
  min-height: 15vh;
  //   border: 2px solid #f8d252;

  //   padding: 10px 10px 10px 20px;
  /* margin-bottom: 20%; */
  border-radius: 20px;
}
.modalClass {
  display: flex;
  align-items: center;
  min-height: 20vh;
  justify-content: center;
}

.modalView > div > div > div > img:first-child {
  width: 7vmin;
  height: 7vmin;
  object-fit: contain;
  float: right;
  margin: 1vw;
  right: 7vmin;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;
}
