$primary: #15172d;

.home {
  .home-table {
    margin-top: 30px;
    td {
      width: 14%;
    }
  }
  .mint {
    background: #00b7c11e;
    width: 350px;
    margin-top: 15px;
    margin-bottom: 30px;
    min-height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 40px 10px 40px;
    border-radius: 15px;
    .connect-button {
      min-width: 180px;
    }
    > div {
      width: 100%;
      margin: 15px 0;
      padding: 10px;
    }
    .header {
      height: 30px;
      width: 20%;
      position: relative;
      top: -15px;
      margin: 0;
      padding: 2px 30px;
      align-items: center;
      font-weight: 600;
      color: #cacbcd;
      background: $primary;
      border-radius: 10px;
      text-transform: uppercase;
      border: 1px solid #00b6c14d;
    }
    .details {
      color: #cacbcda8;
      font-size: 0.9rem;

      padding: 3px 0;
    }
    .item {
      position: relative;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.3rem;
      color: #cacbcd;
      border-radius: 10px;
      img {
        width: 17px;
        margin-left: 10px;
      }
      > input,
      > span,
      > div {
        width: 33%;
      }

      > input {
        background: $primary;
        outline: none;
        border: none;
        font-family: karla;
        font-size: 1.3rem;
        color: #cacbcd;
      }
      > span {
        font-size: 0.68em;
        color: #afb0b123;
      }
      *:focus {
        outline: 0;
      }
    }
  }
}
