$primary: #15172d;

.main-heading {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #cacbcd;
  padding: 2vw;
}
.manage {
  display: flex;
  flex-direction: column;
  padding: 0 6vw;

  .manage-table {
    td {
      width: 25%;
    }
  }
  .item {
    position: relative;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    color: #223d74;
    border-radius: 10px;
    width: 37vw;
    margin: 15px 0;
    padding: 10px;

    > input {
      background: $primary;
      outline: none;
      border: none;
      text-align: center;
      width: 100%;
      font-family: karla;
      font-size: 1.1rem;
      color: #cacbcd;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cacbcda4;
      font-family: karla;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cacbcda4;
      font-family: karla;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #cacbcda4;
      font-family: karla;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #cacbcda4;
      font-family: karla;
    }

    *:focus {
      outline: 0;
    }
  }

  .details-section {
    flex-direction: column;
    background: #00b7c12a;
    border-radius: 15px;
    padding: 30px;
    margin-bottom: 50px;

    .item2 {
      padding: 3px;
      width: 85%;
      margin: 8px;
      input {
        color: #cacbcd70;
        font-size: 0.9rem;
        width: 90%;
      }
    }

    > div:first-child {
      color: #cacbcd75;
      margin-bottom: -10px;
      text-transform: uppercase;
    }
  }
}

.manage-details2 {
  border-radius: 15px;
  // padding: 2vw;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: #00b7c12a;
}

.manage-details {
  // margin: 2vw;
  border-radius: 15px;
  padding-bottom: 2vw;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: #00b7c12a;

  .section-block {
    .connect-button {
      width: 70% !important;
      padding: 7px 5px !important;
      margin: 8px !important;
    }
  }
  .item2 {
    padding: 7px 5px;
    width: 85%;
    margin: 8px;
    border-radius: 15px;
    input {
      color: #cacbcd70;
      font-size: 0.9rem;
      width: 100%;
    }
  }

  .item {
    width: 70%;
    // margin: 15px 0;
    // padding: 10px;
  }
  .section {
    margin: 0 0px 0px 0px;
    display: flex;
    width: 100%;
    // align-items: center;
    justify-content: space-between;
    .bottom-bar {
      flex-direction: column;

      margin: 8px 0 17px 0;
      > div {
        padding-right: 5px !important;
        padding-left: 5px !important;
        width: 80% !important;
      }
    }
    > div {
      > div {
        width: 100%;
        // padding: 10px !important;
      }
      margin: 1vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 43%;
    }
    // flex-direction: column;
    .section-data {
      border: 1px solid #00b6c180;
      border-radius: 10px;
      background: $primary;

      > div {
        display: flex;
        justify-content: space-between;
        margin: 30px 20px;
        > span:first-child {
          color: #cacbcd77;
        }
        > span:last-child {
          color: #cacbcdc5;
        }
      }
    }

    .section-block {
      background: #00b7c125;
      border-radius: 10px;
      margin: 0 1vw;
      display: flex;
      padding: 10px;
      min-height: 280px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .heading {
      text-transform: uppercase;
      text-align: left;
      margin-left: 1vw;

      color: #cacbcd7c;
    }
    > div:first-child {
      // width: 35%;
      // border: 1px solid #00b6c180;
      // border-radius: 10px;
      // background: $primary;
    }
    > div:last-child {
      // width: 65%;
    }
  }

  .bottom-div {
    margin: 15px 0px;
    > diV {
      margin: 20px;
    }
  }
  .small-button {
    border-radius: 20px;
    border: 2px solid #00b6c1;
    padding: 3px 5px;
    color: #cacbcd;
    display: inline-block;
    min-width: 80px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 0.9rem;
  }

  .small-button:hover {
    border-radius: 10px;
    border: 2px solid #cacbcd73;
    background: rgba(0, 0, 0, 0.075);
    padding: 3px 5px;
    color: #00b6c1;
  }
}

.big-button {
  width: 80% !important;
  padding: 8px 0 !important;
  margin: 30px 0;
}

.big-button2 {
  width: 20% !important;
  padding: 8px 0 !important;
  margin: 5px 0;
  margin-left: 48%;
}
.inside-button {
  width: 100% !important;
  margin-top: 7px;
}

@media only screen and (max-width: 800px) {
  .details-section {
    .item {
      width: 90%;
      > input {
        font-size: 15px;
      }
    }
  }
  .manage {
    padding: 0;
  }
  .manage-details {
    > div:nth-child(2) {
      width: 90% !important;
    }
    .section {
      flex-direction: column;
      align-items: center;
      > div {
        width: 90%;
      }
      .section-block {
        padding: 20px 0;
        margin: 0;
        margin-top: 20px;
      }
    }
    .big-button2 {
      width: 40% !important;
      margin-left: inherit;
    }
  }
}
